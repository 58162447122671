<template>
  <div
    class="h-screen flex w-full items-center justify-center"
    :class="getDentalVersionActive ? 'bg-image-dental' : 'bg-image-kuyum'"
  ></div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";

export default {
  computed: {
    getDentalVersionActive() {
      return this.$store.getters.getDentalVersionActive;
    },
  },
  methods: {
    AcceptAlert() {
      this.$router.push("/login").catch(() => {});
    },
    MailActivationResponse(status, data) {
      if (status == 200) {
        this.$vs.dialog({
          color: "dark",
          title: this.$t("Email_Activation"),
          text: this.$t("Email_Message_1"),
          accept: this.AcceptAlert(),
          cancel: this.AcceptAlert(),
          close: this.AcceptAlert(),
        });
      } else if (status == 400 && data == "User not found") {
        this.$router.push("/register").catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
      } else if (status == 400 && data == "Email already confirmed.") {
        this.$vs.dialog({
          color: "dark",
          title: this.$t("Email_Activation"),
          text: this.$t("Email_Message_2"),
          accept: this.AcceptAlert(),
          cancel: this.AcceptAlert(),
          close: this.AcceptAlert(),
        });
      }
    },
  },
  created() {
    var user = this.$route.query.user;
    var token = this.$route.query.token;
    if (user != null && token != null) {
      Api.get(
        API.BASEURL + API.EMAILACTIVATION + user + "/" + token,
        this.MailActivationResponse
      );
    } else {
      this.$router.push("/login").catch((error) => console.error(error));
    }
  },
};
</script>

<style>
.bg-image-dental {
  background-image: url("/img/dental-login-bg.jpg");
  background-position: center;
  background-repeat: repeat;
}
.bg-image-kuyum {
  background-image: url("/img/kuyum-login-bg.jpg");
  background-position: center;
  background-repeat: repeat;
}
</style>
